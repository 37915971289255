export const typesenseErrorConfig = {
  message: 'errors.typesenseMessage',
  statusCode: 500,
  statusMessage: 'Internal Server Error',
}

export const mongodbErrorConfig = {
  message: 'errors.mongodbMessage',
  statusCode: 500,
  statusMessage: 'Internal Server Error',
}

export const directusErrorConfig = {
  message: 'errors.directusMessage',
  statusCode: 500,
  statusMessage: 'Internal Server Error',
}

export const choIdentificationErrorConfig = {
  message: 'errors.choPageMessage',
  statusCode: 404,
  statusMessage: 'Not Found',
}

export const pageIdentificationErrorConfig = {
  message: 'errors.contentPageMessage',
  statusCode: 404,
  statusMessage: 'Not Found',
}
