import { defineNuxtPlugin } from '#app'
import { onMounted } from 'vue'
import VueMatomo from 'vue-matomo'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  if (!config.public.matomoHost || !config.public.matomoSiteId) {
    console.log('matomo: \t\t\t disabled')
  } else {
    nuxtApp.vueApp.use(VueMatomo, {
      host: config.public.matomoHost,
      siteId: config.public.matomoSiteId,
      router: nuxtApp.$router,
      enableLinkTracking: true,
      requireConsent: false,
      trackInitialView: true,
      disableCookies: true,
      requireCookieConsent: false,
    })
    console.log('matomo: \t\t\t enabled')
  }
})
