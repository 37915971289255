// keep import otherwise as there is also a nuxt useColorMode
import { useColorMode } from '@vueuse/core'

export default function () {
  const schemes = [
    {
      title: 'lightColors',
      mode: 'light',
      icon: 'bi-sun',
    },
    {
      title: 'darkColors',
      mode: 'dark',
      icon: 'bi-moon',
    },
    {
      title: 'contrastColors',
      mode: 'contrast',
      icon: 'bi-universal-access',
    },
    {
      title: 'autoColors',
      mode: 'auto',
      icon: 'bi-circle-half',
    },
  ]

  const currentScheme = useColorMode({
    emitAuto: true,
    modes: {
      contrast: 'dark contrast',
    },
  })

  return { currentScheme, schemes }
}
