export function useTestCookieId(cookieId) {
  const cookieConsent = useCookie('kulturpool-cookie-consent')

  return computed(() =>
    cookieConsent.value
      ? cookieConsent.value.split(encodeURIComponent('|')).includes(cookieId)
      : false
  )
}

export function useEnableCookieId(cookieId) {
  const cookieConsent = useCookie('kulturpool-cookie-consent')
  if (
    cookieConsent.value &&
    !cookieConsent.value.split(encodeURIComponent('|')).includes(cookieId)
  ) {
    cookieConsent.value =
      cookieConsent.value + encodeURIComponent('|') + cookieId
  }
}
