export function shuffle(array: any[]) {
  let currentIndex = array.length,
    randomIndex

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    ;[array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ]
  }

  return array
}

// Helper function to pick a number of items from an array and randomize the order
// fallbock if numItems is 0 to original array
declare global {
  interface Array<T> {
    pickItemsAndRandomize(
      numItems: number | undefined,
      randomize?: boolean
    ): T[]
  }
}

Array.prototype.pickItemsAndRandomize = function (
  numItems: number | undefined,
  randomize = false
) {
  let result = this
  if (randomize) {
    result = result.sort(() => Math.random() - 0.5)
  }
  if (numItems ?? 0 > 0) {
    result = result.slice(0, numItems)
  }
  return result
}
