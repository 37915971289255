/**
 * Transforms the object structure of localized contents from
 * the likes of [{ language_code: 'de', title: 'hallo' }, { language_code: 'en', title: 'hello' }]
 * to the likes of { title: { 'de': 'hallo', 'en': 'hello' }}
 */
export function flipTranslatedObject(item: any) {
  const { translations, ...rest } = item

  const data = { ...rest }

  for (const translation of translations) {
    for (const translatedItem in translation) {
      data[translatedItem] = {}
    }
  }

  for (const translation of translations) {
    const languageCode = translation.languages_code

    for (const translatedItem in translation) {
      data[translatedItem][languageCode] = translation[translatedItem]
    }
  }
  return data
}

export function emptyArrToNull<T>(arr: T[]) {
  const filtered = arr.filter((item) => item !== '')
  return filtered.length === 0 ? null : (arr as NonEmptyArray<T>)
}

export function emptyStrToNull(str: string) {
  return str === '' ? null : str
}
