export default defineNuxtRouteMiddleware(async (to, _) => {
  const router = useRouter()
  const validRouteNames = router.getRoutes().map((route) => route.name)
  // on server we load the redirects from the store
  const redirectsStore = useRedirectsStore()
  await callOnce('redirects-init', redirectsStore.init)
  const resolve = redirectsStore.redirects.find((redirect) => {
    if (redirect.is_regex) {
      return new RegExp(redirect.link_from).test(to.href)
    }
    return redirect.link_from === to.href
  })
  if (resolve) {
    const url = resolve.is_regex
      ? to.href.replace(new RegExp(resolve.link_from), resolve.link_to)
      : resolve.link_to
    console.log(
      `Redirecting from ${to.href} to ${url} with code ${resolve.redirect_code}`
    )
    return navigateTo(url, {
      redirectCode: resolve.redirect_code,
      external: true,
    })
  }
  if (!validRouteNames.includes(to.name)) {
    throw createError({ ...pageIdentificationErrorConfig, fatal: true })
  }
})
