<script setup>
import markerSDK from '@marker.io/browser'

const { t } = useI18n()

const widgetLoaded = ref(false)

try {
  const widget = await markerSDK.loadWidget({
    project: useRuntimeConfig().public.markerIoProjectId,
    silent: true,
  })

  widgetLoaded.value = true

  onMounted(() => {
    const reportBugButton = document.querySelector('.report-bug-button')
    reportBugButton.addEventListener('click', () => {
      widget.capture()
    })
  })

  console.log(`marker.io: \t\t loaded`)
} catch {
  console.log(`marker.io: \t\t failed`)
}

const isFeedbackButtonVisible = ref(true)

function removeButton() {
  isFeedbackButtonVisible.value = false
}
</script>

<template>
  <div
    v-show="isFeedbackButtonVisible && widgetLoaded"
    class="report-button-wrapper"
  >
    <button class="report-bug-button" tabindex="1">
      {{ t('feedback.giveFeedback') }}
    </button>

    <IconButton
      class="close-button"
      icon-class="bi-x-lg"
      :button-title="t('feedback.hideButton')"
      tabindex="1"
      @click.stop="removeButton"
    />
  </div>
</template>

<style scoped lang="scss">
.report-button-wrapper {
  position: fixed;
  bottom: 0;
  right: var(--layout-margin-h);
  @extend %button-small;
  border-bottom: none;
  z-index: 9999;
}

.close-button,
.report-bug-button {
  &:focus-visible {
    @extend %focus-visual;
  }
}
</style>
