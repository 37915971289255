export const useRedirectsStore = defineStore('redirects', () => {
  /* === init === */
  const { $directus, $readItems } = useNuxtApp()

  /* === state === */
  const redirects = ref([])

  /* === actions === */
  async function init() {
    try {
      const data = await $directus().request(
        $readItems('redirects', {
          fields: ['*'],
        })
      )
      redirects.value = data
    } catch (err) {
      console.warn(err)
    }
  }

  return {
    // state
    redirects,
    // actions
    init,
  }
})
