<script setup>
const chos404 = [
  {
    title: 'Die Zeitungsleser',
    creator: 'Josef Danhauser',
    date: '1840',
    rightsHolder: 'Österreichische Galerie Belvedere',
    license: 'CC BY-SA',
    licenseURL: 'https://creativecommons.org/licenses/by-sa/4.0/',
    isShownBy:
      'https://sammlung.belvedere.at/internal/media/downloaddispatcher/4087',
  },
  {
    title: 'Restaurant Hanayashiki (Hanayashiki 花やしき)',
    creator:
      'Anonym, Ogata Gekkō 尾形月耕 (Entwurf), Matsuki Heikichi 松木平吉 (Verlag)',
    date: '1899',
    rightsHolder: 'MAK - Museum für angewandte Kunst',
    license: 'InC',
    licenseURL: 'https://rightsstatements.org/page/InC/1.0/',
    isShownBy:
      'https://sammlung.mak.at/img/800x800/publikationsbilder/bi-13215-38_1.jpg',
  },
]

const shownCho = shuffle(chos404)[0]
const titleLine =
  [shownCho.title, shownCho.creator].filter((item) => item).join(', ') +
  (shownCho.date ? ` (${shownCho.date})` : '')
const rightsLine = shownCho.rightsHolder
</script>

<template>
  <section class="content-wrapper">
    <div class="cho-wrapper">
      <img class="cho" :src="shownCho.isShownBy" :alt="titleLine" />
    </div>
    <div class="citation-wrapper">
      <div class="citation">
        <p>{{ titleLine }}</p>
        <p>{{ rightsLine }}</p>
        <a :href="shownCho.licenseURL">{{ shownCho.license }}</a>
      </div>
    </div>
  </section>
</template>

<style scoped lang="scss">
.content-wrapper {
  margin-top: 5dvh;
  margin-top: 5vh;
  width: 100vw;
}

.cho-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.citation-wrapper {
  width: 100%;
}

.cho {
  max-height: 20rem;
  max-width: 60%;
  aspect-ratio: initial;
}

.citation {
  height: 100%;
  width: 100%;
  padding: 1rem var(--layout-margin-h);
  font-size: 0.8rem;
  line-height: 1.3;
}

a {
  text-decoration: underline;
}

@include media('>extra-large') {
  .cho {
    max-height: 25rem;
  }
}

@include media('>ultra-large') {
  .cho {
    max-height: 30rem;
    max-width: 70%;
    aspect-ratio: initial;
  }

  .content-wrapper {
    margin-top: 10dvh;
    margin-top: 10vh;
  }
}
</style>
