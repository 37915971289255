<script setup>
const { t, locale } = useI18n()

const linkMap = new Map([
  ['de', '/inhalte/datenschutz'],
  ['en', '/en/content/privacy-policy'],
])
</script>

<template>
  <i18n-t keypath="cookies.declaration" tag="span">
    <template #policyLink>
      <NuxtLink :to="linkMap.get(locale)">{{
        t('cookies.privacyPolicy')
      }}</NuxtLink>
    </template>
  </i18n-t>
</template>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
