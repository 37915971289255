import { singleIndex } from 'instantsearch.js/es/lib/stateMappings'
import { stringify, parse } from 'qs'
import _ from 'lodash'

export function useVueInstantsearchRouting() {
  const vueInstantsearchRouter = useVueInstantsearchRouter()

  return {
    stateMapping: singleIndex(INDEX_NAME),
    router: vueInstantsearchRouter.combiRouter,
  }
}

const defaultState = {
  query: undefined,
  sortBy: undefined,
  refinementList: undefined,
  range: undefined,
}

function routeStateActuallyChanged(oldState, newState) {
  const stateChange = [
    oldState.query !== newState.query,
    oldState.sortBy !== newState.sortBy,
    !_.isEqual(oldState.refinementList, newState.refinementList),
    !_.isEqual(oldState.range, newState.range),
  ].some((condition) => condition)

  return stateChange
}

export const customHistory = ref([])

export function useVueInstantsearchRouter() {
  const vueRouter = useRouter()
  const aisState = ref(defaultState)
  const searchStore = useSearchStore()

  const combiRouter = {
    /* === read from the URL and return a routeState === */
    read() {
      const { query } = vueRouter.currentRoute.value

      aisState.value = {
        query: query.query,
        refinementList: query.refinementList && parse(query.refinementList),
        range: query.range && parse(query.range),
        sortBy: query.sortBy,
      }

      return aisState.value
    },
    /* === write to the URL === */
    write(routeState) {
      // timeouts: otherwise ais sometimes gets stuck in a refresh loop

      if (routeStateActuallyChanged(aisState.value, routeState)) {
        setTimeout(() => {
          vueRouter.push({
            query: {
              query: routeState.query,
              refinementList:
                routeState.refinementList &&
                stringify(routeState.refinementList),
              range: routeState.range && stringify(routeState.range),
              sortBy: routeState.sortBy,
            },
          })

          searchStore.setActionsAfterRender(scrollToTop)
        }, 10)
      }
    },
    onUpdate(cb) {
      // timeouts: otherwise ais sometimes gets stuck in a refresh loop
      if (typeof window === 'undefined') return

      // only set route query params if actually navigating on catalogue
      // at the moment exclusively needed for (demo) searches accessed via vue router
      this._removeAfterEach = vueRouter.afterEach((to, _) => {
        if (to.name.startsWith('search')) {
          setTimeout(() => {
            cb(this.read())
          }, 10)
        }
      })

      this._onPopState = () => {
        setTimeout(() => {
          cb(this.read())
        }, 10)
      }

      window.addEventListener('popstate', this._onPopState)
    },
    dispose() {
      if (typeof window === 'undefined') {
        return
      }
      if (this._onPopState) {
        window.removeEventListener('popstate', this._onPopState)
      }
      if (this._removeAfterEach) {
        this._removeAfterEach()
      }
    },
  }

  return { combiRouter, vueRouter }
}
