import { customHistory } from '../composables/routing'
import _ from 'lodash'

function wasLanguageSwitch(to, from) {
  const [toName, toLang] = to.name.split('___')
  const [fromName, fromLang] = from.name.split('___')
  const _to = { name: toName, query: to.query }
  const _from = { name: fromName, query: from.query }
  return toLang !== fromLang && _.isEqual(_to, _from)
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (from || !wasLanguageSwitch(to, from)) {
    const [name, lang] = to.name.split('___')
    customHistory.value.push({ name, lang, query: to.query })
  }
  return
})
